<template>
  <div
    class="recommendation-card-container d-flex flex-column justify-space-between"
  >
    <div>
      <div class="recommendation-card-container__subtitle">
        <p class="mb-0">TOP RECOMMENDATION</p>
      </div>
      <div class="recommendation-card-container__title mt-2">
        <p class="mb-0">{{ title }}</p>
      </div>
    </div>
    <div
      class="issue-link"
      :class="{ 'disabled-button': title === 'No issues available' }"
    >
      <button :disabled="title === 'No issues found'">
        <template>
          <router-link :to="routerLinkTo"> ADDRESS ISSUE </router-link>
        </template>
        <!-- <template v-else> ADDRESS ISSUE </template> -->
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecommendationCard",
  props: {
    issue: {
      type: Object,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: null,
    },
    siteId: {
      type: Number,
      required: false,
      default: null,
    },
    page: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    title() {
      if (this.issue) {
        return this.issue.name;
      }
      return "No issues found";
    },
    issueDefId() {
      if (this.issue) {
        return this.issue.turbine_issue_def_id;
      }
      return null;
    },
    issueId() {
      if (this.issue) {
        return this.issue.id;
      }
      return null;
    },
    routerLinkTo() {
      const siteId = parseInt(this.$route.params.siteId);
      if (this.title !== "No issues found") {
        if (this.page === "groupedSiteComponents") {
          return {
            name: "Check Detail",
            params: {
              siteId,
              issueDefId: this.issueDefId,
            },
          };
        } else {
          return {
            name: "NewIssueDetail",
            params: {
              issueId: this.issueId,
            },
          };
        }
      }
      return {};
    },
  },
};
</script>

<style lang="scss" scoped>
.recommendation-card-container {
  border: 1px solid var(--v-border-base);
  height: 202px;
  border-radius: 8px;
  padding: 1rem;

  &__subtitle {
    p {
      font:
        0.75rem "Museo Sans Rounded",
        sans-serif;
      color: var(--v-black3-base) !important;
      font-weight: 600;
    }
  }

  &__title {
    p {
      font:
        1.25rem "Museo Sans Rounded",
        sans-serif;
      color: var(--v-text-base);
      font-weight: 600;
    }
  }
}

.issue-link {
  display: flex;
  justify-content: flex-end;
  font-size: 0.75rem;
  font-family: "Inter", sans-serif;
  button a {
    border: 1.75px solid var(--v-inverse-base);
    border-radius: 3rem;
    padding: 0.25rem 1rem 0.25rem 1rem;
    background-color: transparent;
    color: var(--v-text-base);
    cursor: pointer;
    text-decoration: none;
  }
  button a:hover {
    background-color: var(--v-hover-base);
    text-decoration: none;
  }
}
.disabled-button {
  // border: 1.75px solid var(--v-inverse-base);
  // border-radius: 3rem;
  // padding: 0.25rem 1rem 0.25rem 1rem;
  // background-color: transparent;
  // color: var(--v-text-base);

  button a:hover {
    cursor: not-allowed;
  }
}
</style>

<template>
  <div class="issues-tab-container">
    <TableHeaderFilter
      :headers="issuesHeaders"
      :selectedHeaders="selectedHeaders"
      menuActivator="#site-components-issue-col-filter"
      @changeHeaders="changeSelectedHeaders"
    />
    <DynamicTable
      :headers="selectedHeaders"
      :items="issues"
      :loading="loading"
      :hasFooter="true"
      menuActivator="site-components-issue-col-filter"
      pageType="allComponents"
      tabSelected="issues"
      @toggleIssueTypesUnconfirmed="toggleUnconfirmed"
    >
      <template v-slot:name="{ item }">
        <router-link
          :to="{
            name: 'Check Detail',
            params: {
              siteId: siteId,
              issueDefId: item.id,
            },
          }"
          class="link-cell"
        >
          <div class="d-flex justify-space-between flex-wrap">
            <div class="link-text">{{ item.name }}</div>
            <div class="mr-1 pointer">
              <v-icon size="1.25rem">mdi-open-in-new</v-icon>
            </div>
          </div>
        </router-link>
      </template>
      <template v-slot:issues_in_progress="{ item }">
        <div class="d-flex flex-wrap align-center">
          <div
            v-for="(issue, index) in item.issues_in_progress"
            :key="issue.work_order_id"
          >
            <div v-if="index < 2">
              <router-link
                :to="{
                  name: 'NewIssueDetail',
                  params: {
                    issueId: issue.turbine_issue_id,
                  },
                }"
              >
                <div class="issue-in-progress mr-1">
                  {{ issue.work_order_id }}
                </div></router-link
              >
            </div>
          </div>
          <div
            class="show-all-issues-container"
            v-if="item.issues_in_progress && item.issues_in_progress.length > 2"
          >
            <div
              @click="showAllModal(item)"
              class="show-all-issues-display ml-2"
            >
              {{ showAllIssuesInProgress ? "Show less" : "Show all" }}
            </div>
            <div
              v-if="showAllIssuesInProgress && selectedItem === item"
              class="all-issues"
            >
              <div class="d-flex justify-space-around">
                <p
                  class="ma-0"
                  v-for="issue in selectedItem.issues_in_progress"
                  :key="issue"
                >
                  {{ issue }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:impact_mwh="{ item }">
        <div>
          <span class="mr-1">{{ item.impact_mwh }} MWh</span><span>/</span
          ><span class="ml-1">$</span>{{ item.impact_usd }}
        </div>
      </template>
    </DynamicTable>
  </div>
</template>

<script>
import TableHeaderFilter from "@/components/TableHeaderFilter.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import debounce from "lodash/debounce";

export default {
  name: "AllSiteComponentsIssueTab",
  components: {
    TableHeaderFilter,
    DynamicTable,
  },
  props: {
    issues: {
      type: Array,
      required: false,
      default: () => [],
    },
    siteId: {
      type: Number,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showAllIssuesInProgress: false,
      selectedIssue: null,
      issuesHeaders: [
        {
          id: 1,
          text: "ISSUE NAME",
          value: "name",
        },
        {
          id: 2,
          text: "OPEN ISSUES",
          value: "open",
        },
        {
          id: 3,
          text: "TICKETS IN PROGRESS",
          value: "issues_in_progress",
          not_sortable: true,
        },
        {
          id: 4,
          text: "IMPACT (MWh/$)",
          value: "impact_mwh",
        },
        {
          id: 5,
          text: "ISSUES CLOSED",
          value: "closed",
        },
        {
          id: 6,
          text: "LAST UPDATED",
          value: "lastUpdated",
        },
      ],
      selectedColumns: [],
      maxColumns: 6,
    };
  },
  computed: {
    selectedHeaders() {
      let result = this.issuesHeaders;
      if (this.selectedColumns?.length > 0) {
        result = result.filter((item) => {
          const matchedHeader = this.selectedColumns.filter((column) => {
            return column.id === item.id;
          });
          if (matchedHeader?.length > 0) {
            return item;
          }
        });
        return result;
      }
      return [];
    },
  },
  methods: {
    showAllModal(item) {
      this.selectedItem = item;
      this.showAllIssuesInProgress = !this.showAllIssuesInProgress;
    },
    changeSelectedHeaders(header) {
      if (header === "selectAll") {
        if (
          this.selectedColumns.length === this.issuesHeaders.length ||
          this.selectedColumns.length === this.maxColumns
        ) {
          this.selectedColumns = [];
          return;
        } else {
          this.selectedColumns = this.issuesHeaders;
        }
      } else if (header === "reset") {
        this.selectedColumns = this.issuesHeaders;
      } else if (header === "clear") {
        this.selectedColumns = [];
      } else {
        const index = this.selectedColumns.indexOf(header);
        if (index !== -1) {
          this.selectedColumns = this.selectedColumns.filter(
            (item) => item !== header,
          );
        } else {
          if (this.selectedColumns.length >= this.maxColumns) {
            this.selectedColumns.shift();
          }
          this.selectedColumns.push(header);
        }
      }
      this.ensureMaxColumns();
    },
    handleResize() {
      this.ensureMaxColumns();
    },
    ensureMaxColumns() {
      const width = window.innerWidth;
      this.maxColumns = width < 900 ? 4 : this.issuesHeaders.length;
      if (this.selectedColumns.length > this.maxColumns) {
        this.selectedColumns = this.selectedColumns.slice(0, this.maxColumns);
      }
    },
    toggleUnconfirmed(showUnconfirmedOnly) {
      // Continue the emit to all site components
      this.$emit("toggleUnconfirmed", showUnconfirmedOnly);
    },
  },
  mounted() {
    this.selectedColumns = this.issuesHeaders;
    this.handleResize = debounce(this.handleResize, 200);
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.issues-tab-container {
  position: relative;
}

.issue-status {
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  border-radius: 12px;
}

.all-issues {
  position: absolute;
  bottom: 0;
  right: 75px;
  width: 250px;
  border: 1px solid var(--v-mediumGray-base);
  border-radius: 6px;
  box-shadow: 2px 2px 8px 4px rgba(13, 13, 13, 0.3);
  background: var(--v-issueModal-base);
  z-index: 1000;
  padding: 0.5rem;
}
</style>

<template>
  <div>
    <DataLoading v-if="false" />
    <div v-else>
      <header>
        <div class="component-dashboard__header d-flex align-center">
          <h3 class="d-flex align-center">
            <span
              class="main-title"
              :class="
                componentName.includes('rotor-shaft') ? '' : 'capitalize-name'
              "
            >
              All Site
              {{
                componentName.includes("rotor-shaft")
                  ? "Rotor-shaft Assemblies"
                  : componentName
              }}
            </span>
          </h3>
          <div
            class="component-dashboard__header__help d-flex align-center justify-space-between"
          >
            <v-icon @click="handleClickInfoIcon">{{ informationIcon }}</v-icon>
          </div>
        </div>
      </header>
      <div class="outer-grid">
        <div class="row-1-col-1">
          <div class="map-container mt-1">
            <MapView
              :mapData="mapConfig"
              :isMapLoading="loading.getTurbinesBySiteId"
              height="400px"
            />
          </div>
        </div>
        <div class="row-1-col-2">
          <AtAGlance
            :page="'groupedSiteComponents'"
            :issuesLoading="loadingIssuesGroupedByComponent"
          >
            <!-- Need to figure out where this data is coming from -->
            <!-- <template v-slot:header>
              <div class="d-flex heading-text mb-1">Updated 2023-11-30</div>
            </template> -->
            <template v-slot:content>
              <div class="content-container ml-1">
                <div
                  class="d-flex align-center justify-space-between orientation-header mt-3 mb-6"
                >
                  <!-- <p class="d-flex">
                    IN THE LAST
                    <span class="days-menu mr-1 ml-1 pointer"
                      ><p class="mb-0">30</p>
                       <v-menu
                        offset-x
                        left
                        offset-overflow
                        attach=".select-days-btn"
                        activator=".select-days-btn"
                        v-if="openMenu"
                      >
                        <v-list>
                          <v-list-item
                            v-for="(item, index) in days"
                            :key="index"
                          >
                            <v-list-item-title
                              @click="updateDays(item.value)"
                              >{{ item.text }}</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </span>
                    DAYS
                  </p> -->
                  <p class="d-flex">ALL ISSUES</p>
                  <!-- <button class="select-days-btn" @click="openMenu = true">
                    SELECT DAYS
                  </button> -->
                </div>
                <p class="mb-3 content-body error--text">
                  {{ criticalIssuesCount }} critical issues
                </p>
                <div>
                  <p class="at-a-glance-body-footer">
                    <span class="new-issues">{{ newIssuesCount }} new</span
                    ><span class="mr-1">,</span>
                    <span class="in-progress-issues"
                      >{{ inProgressIssuesCount }} in progress</span
                    ><span class="mr-1">,</span>
                    <span class="closed-issues"
                      >{{ closedIssuesCount }} closed</span
                    >
                  </p>
                  <!-- <p>Data: 98%</p> -->
                </div>
              </div>
            </template>
            <template v-slot:footer>
              <div
                class="top-recommendation-cards d-flex justify-space-between"
              >
                <div class="top-recommendation-cards__card1">
                  <RecommendationCard
                    :issue="assetRecommendation1"
                    type="highestAEP"
                    page="groupedSiteComponents"
                    :siteId="siteId"
                  />
                </div>
                <div class="top-recommendation-cards__card2">
                  <RecommendationCard
                    :issue="assetRecommendation2"
                    type="asset"
                    page="groupedSiteComponents"
                    :siteId="siteId"
                  />
                </div>
              </div>
            </template>
          </AtAGlance>
        </div>
        <div class="row-2-col-1-2">
          <div class="new-tab">
            <button
              v-for="tab in tabs"
              :ref="tab.name"
              :key="tab.id"
              :class="{ tablinks: true, active: activeTab === tab.id }"
              @click="openTab(tab.id, tab.name, 'click_event')"
            >
              {{ tab.name }}
            </button>
          </div>
          <div class="tabbed-section">
            <div :id="componentName" class="tabcontent" v-if="activeTab === 1">
              <AllSiteComponentsTab
                :issues="issuesByTurbineList"
                :component="componentName"
                :loading="loadingIssuesGroupedByTurbine"
              />
            </div>
            <div id="Issues" class="tabcontent" v-show="activeTab === 2">
              <AllSiteComponentsIssueTab
                :issues="issuesForIssueTab"
                :siteId="siteId"
                :loading="loadingIssuesGroupedByComponent"
                @toggleUnconfirmed="toggleUnconfirmed"
              />
            </div>
            <div id="Explore" class="tabcontent pa-4" v-if="activeTab === 3">
              <div v-if="!isPowerCurveExplorerOpen && !isEventsViewOpen">
                <NewAnalyticsView
                  ref="analyticsView"
                  :turbineData="turbinesData"
                  :siteId="siteId"
                  pageTitle="allSiteComponents"
                  @setStaticViewRequest="setAnalyticsViewRequest"
                  @openPowerCurveExplorer="togglePowerCurveExplorer"
                  @viewChanged="updateAnalyticsViewSelected"
                />
              </div>
              <div v-if="!isEventsViewOpen && isPowerCurveExplorerOpen">
                <NewPowerCurveExplorer
                  :targetTurbineOptions="targetTurbineOptions"
                  :loadingTurbines="loading.getTurbinesBySiteId"
                  :siteId="siteId"
                  @openPowerCurveExplorer="togglePowerCurveExplorer"
                  @viewChanged="updateAnalyticsViewSelected"
                />
              </div>
              <div v-if="isEventsViewOpen">
                <EventsExplorer
                  page="allSiteComponents"
                  @openEventsView="toggleEventsView"
                  @viewChanged="updateAnalyticsViewSelected"
                  @openPowerCurveExplorer="togglePowerCurveExplorer"
                />
              </div>
            </div>
            <div id="Description" class="tabcontent" v-if="activeTab === 4">
              <div class="component-description">
                <div v-if="componentName === 'converters'">
                  <p class="ma-0">{{ componentDescription.description1 }}</p>
                  <p class="ma-0 mt-2">
                    {{ componentDescription.description2 }}
                  </p>
                  <p class="ma-0 mt-2">
                    {{ componentDescription.description3 }}
                  </p>
                </div>
                <div
                  class="details-widget-container"
                  v-else-if="componentName.includes('rotor-shaft')"
                >
                  <p>
                    The main shaft assembly of a wind turbine, which connects
                    the hub to the gearbox or generator, includes either one or
                    two main bearings depending on its configuration. These
                    bearings, also known as rotor bearings, have been identified
                    as some of the most critical components to the reliability
                    and availability of the turbine's transmission system.
                  </p>
                  <div>
                    <span>
                      There are three main approaches to detecting incipient
                      main bearing damage:
                    </span>
                    <ul>
                      <li>
                        SCADA-based:
                        <ul class="explanation-list-item">
                          <li>
                            Utilizes machine learning to detect issues by
                            analyzing the relationship between friction and
                            temperature correlations.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Vibration-based:
                        <ul class="explanation-list-item">
                          <li>
                            Monitors the mechanical vibrations to identify
                            abnormalities indicating potential damage.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Oil-based:
                        <ul class="explanation-list-item">
                          <li>
                            Analyzes oil samples for wear particles that signal
                            bearing damage.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <p>
                    WindESCo embraces an holistic approach for monitoring main
                    bearing health, harnessing every available data source. Most
                    prevalent being the SCADA-based method, effectively
                    correlating friction and temperature to identify potential
                    issues and preempt failures.
                  </p>
                </div>
                <p class="mb-0" v-else>
                  {{ componentDescription }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="outer-grid-2">
        <AllIssuesCard
          :siteName="siteName"
          page="groupedSiteComponents"
          :issuesByType="issuesByType"
          :loading="loading.getChecks"
          @goToIssues="goToSiteIssues"
        />
        <div class="row-1-col-2">
          <MaintenanceCard
            :maintenanceData="maintenanceData"
            :siteName="siteName"
            :loading="loadingGetMods"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/helpers/axiosAPI";
import { mapActions, mapState } from "vuex";
import DataLoading from "@/components/DataLoading";
import {
  informationIcon,
  mapTileLayerUrl,
  darkMapTileLayerUrl,
  rotorShaftDescription,
  converterDescription,
} from "@/helpers/variables";
import { roundToString } from "@/helpers/functions";
import AtAGlance from "@/components/SummaryCards/AtAGlance";
import AllIssuesCard from "../components/AllIssuesCard.vue";
import RecommendationCard from "@/components/RecommendationCard";
import NewAnalyticsView from "@/components/NewIssueDetailComponents/NewAnalyticsView";
import NewPowerCurveExplorer from "@/components/NewIssueDetailComponents/NewPowerCurveExplorer";
import EventsExplorer from "@/components/NewSiteDashboardComponents/EventsExplorer";
import AllSiteComponentsIssueTab from "@/components/AllSiteComponentsChildren/AllSiteComponentsIssueTab";
import AllSiteComponentsTab from "@/components/AllSiteComponentsChildren/AllSiteComponentsTab";
import MapView from "@/components/MapView";
import MaintenanceCard from "@/components/SummaryCards/MaintenanceCard";
import dayjs from "dayjs";
import mixpanel from "mixpanel-browser";

export default {
  name: "AllSiteComponents",
  components: {
    DataLoading,
    AtAGlance,
    AllIssuesCard,
    RecommendationCard,
    AllSiteComponentsTab,
    AllSiteComponentsIssueTab,
    MapView,
    NewAnalyticsView,
    NewPowerCurveExplorer,
    EventsExplorer,
    MaintenanceCard,
  },
  data() {
    return {
      informationIcon,
      mapData: {
        zoom: 15,
        center: [0, 0],
        url: this.$vuetify.theme.isDark ? darkMapTileLayerUrl : mapTileLayerUrl,
        markers: [],
      },
      roundToString,
      activeTab: 1,
      isPowerCurveExplorerOpen: false,
      staticAnalyticsViewRequest: {},
      openExploreTab: false,
      analyticsFiguresData: null,
      issuesByTurbine: [],
      loadingIssuesGroupedByTurbine: false,
      loadingAnalyticsFigures: false,
      modsByComponent: [],
      loadingGetMods: false,
      issuesByComponent: [],
      loadingIssuesGroupedByComponent: false,
      componentIssues: [],
      loadingComponentIssues: false,
      showUnconfirmedOnly: false,
      isEventsViewOpen: false,
      analyticsViewSelection: "yaw_misalignment",
    };
  },
  computed: {
    ...mapState({
      userData: (state) => state.user.userData,
      siteData: (state) => state.site.siteData,
      turbinesData: (state) => state.site.turbinesData,
      issuesByType: (state) => state.site.checks,
      loading: (state) => state.site.loading,
    }),
    tabs() {
      if (this.componentName) {
        return [
          {
            id: 1,
            name: "COMPONENTS",
          },
          {
            id: 2,
            name: "ISSUES",
          },
          {
            id: 3,
            name: "EXPLORE",
          },
          {
            id: 4,
            name: "DESCRIPTION",
          },
        ];
      }
      return [];
    },
    siteName() {
      if (this.siteData && Object.keys(this.siteData).length > 0) {
        const siteName = this.siteData.name.toUpperCase();
        return siteName;
      }
      return "";
    },
    siteId() {
      return parseInt(this.$route.params.siteId);
    },
    componentName() {
      const componentName = this.$route.params.componentName;
      if (componentName.includes("rotor-shaft")) {
        return "rotor-shaft assemblies";
      }
      const formattedComponentName = componentName.replace("-", " ");
      return formattedComponentName;
    },
    componentDescription() {
      if (this.componentName.includes("rotor-shaft")) {
        return rotorShaftDescription;
      } else if (this.componentName === "converters") {
        const description = {
          description1: converterDescription.description1,
          description2: converterDescription.description2,
          description3: converterDescription.description3,
        };
        return description;
      }
      return "";
    },
    mapConfig() {
      let mapObj = {
        zoom: 12,
        center: [0, 0],
        url: this.$vuetify.theme.isDark ? darkMapTileLayerUrl : mapTileLayerUrl,
      };
      if (this.turbinesData?.length > 0) {
        // eslint-disable-next-line no-undef
        const clonedTurbines = structuredClone(this.turbinesData);
        mapObj.markers = clonedTurbines;
      }
      return mapObj;
    },
    issuesByTurbineList() {
      if (this.issuesByTurbine.length > 0) {
        let componentType = this.queryParamComponentName;
        if (componentType.includes("rotor_shaft")) {
          componentType = "main_bearing";
        }
        const issuesList = this.issuesByTurbine.map((issue) => ({
          turbine: issue.turbine_name,
          turbine_id: issue.turbine_id,
          component: componentType,
          component_name:
            issue.components?.[componentType]?.name ?? "Not registered",
          status: issue.components?.[componentType]
            ? this.setStatusColor(issue.n_open)
            : "gray",
          new_issues:
            issue.n_new && issue.components?.[componentType]?.name
              ? issue.n_new
              : "0",
          in_progress:
            issue.n_in_progress && issue.components?.[componentType]?.name
              ? issue.n_in_progress
              : "0",
          open:
            issue.n_open && issue.components?.[componentType]?.name
              ? issue.n_open
              : "0",
          closed:
            issue.n_fixed_or_resolved && issue.components?.[componentType]?.name
              ? issue.n_fixed_or_resolved
              : "0",
          fixed:
            issue.n_fixed && issue.components?.[componentType]?.name
              ? issue.n_fixed
              : "0",
          impact_mwh: roundToString(issue.aep_loss_mwhpyr, 0),
          impact_usd: roundToString(issue.aep_loss_upper_usdpyr, 0),
          issues_in_progress: issue.work_order_id_to_turbine_issue_id
            ? Object.entries(issue.work_order_id_to_turbine_issue_id).map(
                ([workOrderId, turbineIssueId]) => ({
                  work_order_id: workOrderId,
                  turbine_issue_id: turbineIssueId,
                }),
              )
            : [],
          lastUpdated: issue.last_updated_ts
            ? dayjs(issue.last_updated_ts).format("YYYY-MM-DD")
            : "None",
        }));

        return issuesList;
      } else {
        return [];
      }
    },
    // For the turbine map at top left of screen
    targetTurbineOptions() {
      if (this.turbinesData.length > 0) {
        const options = this.turbinesData.map((turbine) => ({
          id: turbine.id,
          text: turbine.turbine_name,
          value: turbine.id,
          position: turbine.position,
          site_id: turbine.site_id,
          turbine_model: turbine.turbine_model,
          mapInfoTitle: turbine.mapInfoTitle,
          color: "grey",
        }));
        return options;
      } else {
        return [];
      }
    },
    criticalIssuesCount() {
      if (this.issuesByComponent?.length > 0) {
        return this.newIssuesCount + this.inProgressIssuesCount;
      }
      return 0;
    },
    newIssuesCount() {
      if (this.issuesByComponent?.length > 0) {
        const inProgressIssues = this.issuesByComponent.filter(
          (issue) => issue.n_new > 0,
        ).length;
        return inProgressIssues;
      }
      return 0;
    },
    inProgressIssuesCount() {
      if (this.issuesByComponent?.length > 0) {
        const inProgressIssues = this.issuesByComponent.filter(
          (issue) => issue.n_in_progress > 0,
        ).length;
        return inProgressIssues;
      }
      return 0;
    },
    closedIssuesCount() {
      if (this.issuesByComponent?.length > 0) {
        const closedIssues = this.issuesByComponent.filter(
          (issue) => issue.n_fixed_or_resolved > 0,
        ).length;
        return closedIssues;
      }
      return 0;
    },
    maintenanceData() {
      return this.modsByComponent;
    },
    assetIssues() {
      if (this.issuesByComponent.length > 0) {
        const issuesMap = this.issuesByComponent.map((issue) => ({
          id: issue.turbine_issue_def_id,
          name: issue.name,
          description: issue.description,
          n_issues: issue.n ? issue.n : "0",
          new_issues: issue.n_new ? issue.n_new : "0",
          in_progress: issue.n_in_progress ? issue.n_in_progress : "0",
          open: issue.n_open ? issue.n_open : "0",
          closed: issue.n_fixed_or_resolved ? issue.n_fixed_or_resolved : "0",
          fixed: issue.n_fixed ? issue.n_fixed : "0",
          unconfirmed: issue.n_unconfirmed ? issue.n_unconfirmed : "0",
          impact_mwh: roundToString(issue.aep_loss_mwhpyr, 0),
          impact_usd: roundToString(issue.aep_loss_upper_usdpyr, 0),
          issues_in_progress: issue.work_order_id_to_turbine_issue_id
            ? Object.entries(issue.work_order_id_to_turbine_issue_id).map(
                ([workOrderId, turbineIssueId]) => ({
                  work_order_id: workOrderId,
                  turbine_issue_id: turbineIssueId,
                }),
              )
            : [],
          lastUpdated: issue.last_updated_ts
            ? dayjs(issue.last_updated_ts).format("YYYY-MM-DD")
            : "None",
        }));
        return issuesMap;
      }
      return [];
    },
    issuesContainUnconfirmed() {
      // Filter out issues that have unconfirmed issues
      const unconfirmedIssues = this.assetIssues.filter(
        (issue) => issue.unconfirmed > 0,
      );
      if (unconfirmedIssues.length > 0) {
        return unconfirmedIssues;
      }
      return [];
    },
    issuesForIssueTab() {
      if (this.showUnconfirmedOnly) {
        if (this.issuesContainUnconfirmed.length > 0) {
          return this.issuesContainUnconfirmed;
        }
      } else if (this.assetIssues.length > 0) {
        return this.assetIssues;
      }
      return [];
    },
    siteIssuesSummary() {
      if (this.siteData && Object.keys(this.siteData).length > 0) {
        return this.siteData.issues_summary;
      }
      return {};
    },
    openIssues() {
      if (this.componentIssues.length > 0) {
        return this.componentIssues.filter((issue) => issue.is_open === true);
      }
      return [];
    },
    assetRecommendation1() {
      if (this.componentIssues?.length > 0) {
        return this.componentIssues[0];
      }
      return null;
    },
    assetRecommendation2() {
      if (this.componentIssues?.length > 1) {
        return this.componentIssues[1];
      } else {
        return null;
      }
    },
    queryParamComponentName() {
      let componentType = this.componentName;
      if (componentType.includes("rotor-shaft")) {
        componentType = "rotor_shaft_assembly";
      } else if (componentType.charAt(componentType.length - 1) === "s") {
        componentType = componentType.slice(0, -1);
      }
      // Add underscore if there is a space between words
      if (componentType.includes(" ")) {
        componentType = componentType.replace(" ", "_");
      }
      return componentType;
    },
  },
  methods: {
    ...mapActions({
      getSite: "site/getSite",
      getTurbinesBySiteId: "site/getTurbinesBySiteId",
      getIssuesByType: "site/getChecks",
      incrementClickCount: "app/incrementClickCount",
      updateShowBurger: "app/updateShowBurger",
      updateBurgerData: "app/updateBurgerData",
      handleAxiosError: "error/raiseError",
    }),
    handleClickInfoIcon() {
      this.updateShowBurger(true);
      let component = this.componentName;
      if (component.toLowerCase().includes("rotor-shaft")) {
        component = "rotor-shaft assemblies";
      }
      this.updateBurgerData({
        name: `All ${component}`,
        description: this.componentDescription,
      });
    },
    openTab(tabId, tabName, method) {
      const url = new URL(window.location.href);
      const analyticsView = url.searchParams.get("analytics_view");
      if (tabName !== "EXPLORE" && analyticsView) {
        url.search = "";
        history.replaceState(null, null, url);
      }
      this.activeTab = tabId;
      if (method !== "method") {
        mixpanel.track(`site_all_${this.componentName}_tab_click`, {
          tab_name: tabName,
        });
      }
      this.setUrlByTab(tabId);
      if (tabId === 3) {
        // Set this var to true to signal site id watcher to go to explore tab only when site id is available
        if (this.siteId) {
          this.goToExploreTab();
          if (analyticsView === "events") {
            this.isEventsViewOpen = true;
          } else if (analyticsView === "power_curve_explorer") {
            this.isPowerCurveExplorerOpen = true;
          }
        } else {
          this.openExploreTab = true;
        }
      }
      // Using timeout because next tick is not fast enough to see the element
      if (tabName) {
        this.timeout = setTimeout(() => {
          const element = this.$refs[tabName];
          // Scroll to the active tab
          element[0].scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }, 1500);
      }
    },
    // Call this when tab is clicked
    setUrlByTab(tabId) {
      const url = new URL(window.location.href);
      const tab = this.tabs.find((tab) => tab.id === tabId);
      if (tab) {
        let tabName = tab.name;
        // Replace spaces in component name with underscore for url
        if (tabName.includes(" ")) {
          tabName = tabName.replace(" ", "_");
        }
        url.hash = tabName.toLowerCase();
        window.history.replaceState(null, null, url);
      }
    },
    // Call this on mounted
    setTabByUrl(hashName) {
      if (hashName) {
        let tabName = hashName.replace("#", "").toUpperCase();
        const tab = this.tabs.find((tab) => tab.name === tabName);
        if (tab && tab.id) {
          this.openTab(tab.id, tabName, "method");
        }
      }
    },
    isActiveTab(tabId) {
      return this.activeTab === tabId;
    },
    goToExploreTab() {
      if (
        this.siteId &&
        (this.turbinesData?.length === 0 ||
          this.siteId !== this.turbinesData[0]?.site_id)
      ) {
        this.getTurbinesBySiteId(this.siteId);
      }
      this.openExploreTab = false;
    },
    setAnalyticsViewRequest(request) {
      // Request details for setting url params on navigation to site analytics tab
      this.staticAnalyticsViewRequest.site_id = request.site_id;
      this.staticAnalyticsViewRequest.start_date = request.start_date;
      this.staticAnalyticsViewRequest.end_date = request.end_date;
      this.staticAnalyticsViewRequest.turbine_ids = request.turbine_ids;
      this.staticAnalyticsViewRequest.view = request.view;
    },
    togglePowerCurveExplorer(isOpen) {
      this.isPowerCurveExplorerOpen = isOpen;
    },
    toggleEventsView(isOpen) {
      this.isEventsViewOpen = isOpen;
    },
    updateAnalyticsViewSelected(view) {
      this.analyticsViewSelection = view;
      if (view === "events") {
        this.toggleEventsView(true);
      }
    },
    goToSiteIssues(payload) {
      const siteId = parseInt(this.$route.params.siteId);
      this.$router.push({
        name: "SiteDashboard",
        params: {
          siteId,
        },
        query: payload === "default" ? null : payload,
        hash: "#issues",
      });
    },
    setStatusColor(issues) {
      if (issues === 0) {
        return "green";
      } else if (issues > 0 && issues < 5) {
        return "warning";
      } else if (issues >= 5) {
        return "error";
      } else {
        return "lightgray";
      }
    },
    toggleUnconfirmed(showUnconfirmed) {
      this.showUnconfirmedOnly = showUnconfirmed;
    },
    // Call APIs here
    async getModsByComponent() {
      try {
        this.loadingGetMods = true;
        const siteId = parseInt(this.$route.params.siteId);
        const params = {
          component: this.queryParamComponentName.includes("rotor_shaft")
            ? "main_bearing"
            : this.queryParamComponentName,
        };
        const res = await axios.get(`/sites/${siteId}/turbine-mods`, {
          params,
        });

        if (res.status === 200) {
          this.modsByComponent = res.data.results;
        } else {
          const error = {
            name: "getModsByConverter",
            message: "Error API call",
            value: "unknown error",
          };
          this.handleAxiosError(error);
        }
      } catch (error) {
        this.handleAxiosError(error);
      }
      this.loadingGetMods = false;
    },
    async getIssuesGroupedByTurbine() {
      try {
        this.loadingIssuesGroupedByTurbine = true;
        const siteId = parseInt(this.$route.params.siteId);
        const params = {
          component_type: this.queryParamComponentName.includes("rotor_shaft")
            ? "main_bearing"
            : this.queryParamComponentName,
          group_by: "turbine",
          get_losses_gains: true,
          include_component_info: true,
        };

        const res = await axios.get(`/sites/${siteId}/turbine-issues`, {
          params,
        });

        if (res.status === 200) {
          this.issuesByTurbine = res.data.results;
        } else {
          const error = {
            name: "getIssuesGroupedByTurbine",
            message: "Error API call",
            value: "unknown error",
          };
          this.handleAxiosError(error);
        }
      } catch (error) {
        this.handleAxiosError(error);
      }
      this.loadingIssuesGroupedByTurbine = false;
    },
    async getComponentIssues() {
      try {
        this.loadingComponentIssues = true;
        const siteId = parseInt(this.$route.params.siteId);
        const params = {
          component_type: this.queryParamComponentName.includes("rotor_shaft")
            ? "main_bearing"
            : this.queryParamComponentName,
        };

        const res = await axios.get(`/sites/${siteId}/turbine-issues`, {
          params,
        });
        if (res.status === 200) {
          this.componentIssues = res.data.results;
        } else {
          const error = {
            name: "getComponentIssues",
            message: "Error API call",
            value: "unknown error",
          };
          this.handleAxiosError(error);
        }
      } catch (error) {
        this.handleAxiosError(error);
      }
      this.loadingComponentIssues = false;
    },
    async getIssuesGroupedByComponent() {
      try {
        this.loadingIssuesGroupedByComponent = true;
        const siteId = parseInt(this.$route.params.siteId);
        const params = {
          component: this.queryParamComponentName.includes("rotor_shaft")
            ? "main_bearing"
            : this.queryParamComponentName,
        };

        const res = await axios.get(`/sites/${siteId}/checks`, {
          params,
        });

        if (res.status === 200) {
          this.issuesByComponent = res.data;
        } else {
          const error = {
            name: "getIssuesGroupedByComponent",
            message: "Error API call",
            value: "unknown error",
          };
          this.handleAxiosError(error);
        }
      } catch (error) {
        this.handleAxiosError(error);
      }
      this.loadingIssuesGroupedByComponent = false;
    },
  },
  beforeMount() {
    const siteId = +this.$route.params.siteId;
    if (
      this.siteData &&
      (Object.keys(this.siteData)?.length === 0 ||
        this.siteData?.id !== siteId ||
        (this.siteData?.id === siteId && this.siteData?.issues_summary == null))
    ) {
      this.getSite({
        siteId,
        issuesSummary: 1,
      });
    }
    if (
      this.turbinesData &&
      (siteId !== this.turbinesData[0]?.site_id ||
        this.turbinesData?.length === 0)
    ) {
      this.getTurbinesBySiteId(siteId);
    }
    //if (!this.loadingGetIssuesByStatus && this.issuesByType?.length === 0) {
    this.getIssuesByType({ siteId, component_type: this.componentName });
    //  }
    this.getIssuesGroupedByTurbine();
    this.getIssuesGroupedByComponent();
    this.getComponentIssues();
    // Call for maintenance card data
    if (this.modsByComponent.length === 0) {
      this.getModsByComponent({ siteId, component_type: this.componentName });
    }
  },
  mounted() {
    const url = new URL(window.location.href);
    const hashName = url.hash;
    this.setTabByUrl(hashName);
  },
  watch: {
    // Setting up this watcher to run opening explore tab only after site id is available
    siteId: {
      immediate: true,
      handler(value) {
        const url = new URL(window.location.href);
        const analyticsView = url.searchParams.get("analytics_view");
        if (analyticsView === "events") {
          this.isEventsViewOpen = true;
        } else {
          this.isEventsViewOpen = false;
        }
        if (value && this.openExploreTab) {
          this.goToExploreTab();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.component-dashboard {
  &__header {
    &__help {
      color: var(--v-black3-base);
      box-sizing: border-box;
      padding: 2px 16px 8px 8px;
      cursor: pointer;

      .v-icon {
        color: var(--v-black3-base);
      }
      .v-icon:hover {
        color: var(--v-primary-base) !important;
      }
    }
  }
}
// Remove borders when all cards are in place
.outer-grid {
  display: grid;
  grid-template-columns: 1.25fr 1fr;
  grid-template-rows: 380px 1fr;
  gap: 0.75rem;
}
.row-1-col-1 {
  grid-column-start: 1;
  grid-column-end: 2;
}
.row-1-col-2 {
  grid-column-start: 2;
  grid-column-end: 3;
}
.row-2-col-1-2 {
  grid-column-start: 1;
  grid-column-end: 3;
}

.capitalize-name {
  text-transform: capitalize;
}

.map-container {
  height: 378px; // chang height when done editing at a glance card font styles
  width: 100%;
  border: 1px solid var(--v-border-base);
  border-radius: 8px;
  overflow: hidden;
}
.content-container {
  position: relative;
  height: 110px;
  p {
    margin-bottom: 0;
    margin-top: 0;
  }
}
.content-heading {
  margin-top: 10px;
}
.content-body {
  font:
    1.75rem Inter,
    sans-serif;
  font-weight: 500;
  text-transform: capitalize;
}

.top-recommendation-cards {
  height: 100%;
  width: 100%;
  margin-top: 0.75rem;

  &__card1 {
    width: 49%;
  }
  &__card2 {
    width: 49%;
  }
}
.select-days-btn {
  border: 1.75px solid var(--v-inverse-base);
  border-radius: 3rem;
  padding: 0.1rem 0.6rem 0.1rem 0.6rem;
}
.select-days-btn:hover {
  background-color: var(--v-converterBorders-base);
}

.tabbed-section {
  border: 1px solid var(--v-border-base);
  border-radius: 8px;
  border-top-left-radius: 0;
  min-height: 150px;
}

.component-description {
  padding: 1rem;
}

.outer-grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0.75rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.grid-2-row-1-col-1 {
  grid-column-start: 1;
  grid-column-end: 2;
  border: 2px solid blue;
}
.grid-2-row-1-col-2 {
  grid-column-start: 2;
  grid-column-end: 3;
  border: 2px solid blue;
}

@media (max-width: 1264px) {
  .outer-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    gap: 0.5rem;
  }
  .row-1-col-1 {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .row-1-col-2 {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
    margin-bottom: 0;
  }
  .row-2-col-1-2 {
    grid-column-start: 1;
    grid-column-end: 2;
  }
}
</style>

<template>
  <div class="issues-tab-container">
    <TableHeaderFilter
      :headers="headers"
      :selectedHeaders="selectedHeaders"
      menuActivator="#site-components-col-filter"
      @changeHeaders="changeSelectedHeaders"
    />
    <DynamicTable
      :headers="selectedHeaders"
      :items="issues"
      :loading="loading"
      :hasFooter="true"
      menuActivator="site-components-col-filter"
    >
      <template v-slot:name="{ item }">
        <div class="d-flex justify-space-between flex-wrap">
          <div>{{ item.turbine }}</div>
        </div>
      </template>
      <template v-slot:component_name="{ item }">
        <router-link
          v-if="item.component_name != 'Not registered'"
          :to="indvComponentRoute(item)"
          class="link-cell"
        >
          <div class="d-flex justify-space-between">
            <div class="d-flex align-center">
              <div class="link-text">
                {{ item.component_name }}
              </div>
              <v-icon :color="item.status">mdi-circle-medium</v-icon>
            </div>
            <div class="mr-1 pointer">
              <v-icon size="1.25rem">mdi-open-in-new</v-icon>
            </div>
          </div>
        </router-link>
      </template>
      <template v-slot:issues_in_progress="{ item }">
        <div class="d-flex flex-wrap align-center">
          <div
            v-for="(issue, index) in item.issues_in_progress"
            :key="issue.work_order_id"
          >
            <div v-if="index < 2">
              <router-link
                :to="{
                  name: 'NewIssueDetail',
                  params: {
                    issueId: issue.turbine_issue_id,
                  },
                }"
              >
                <div class="issue-in-progress mr-1">
                  {{ issue.work_order_id }}
                </div></router-link
              >
            </div>
          </div>
          <div
            class="show-all-issues-container"
            v-if="item.issues_in_progress && item.issues_in_progress.length > 2"
          >
            <div
              @click="showAllModal(item)"
              class="show-all-issues-display ml-2"
            >
              {{ showAllIssuesInProgress ? "Show less" : "Show all" }}
            </div>
            <div
              v-if="showAllIssuesInProgress && selectedItem === item"
              class="all-issues"
            >
              <div class="d-flex justify-space-around">
                <p
                  class="ma-0"
                  v-for="issue in selectedItem.issues_in_progress"
                  :key="issue.work_order_id"
                >
                  {{ issue.work_order_id }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:impact_mwh="{ item }">
        <div>
          <span class="mr-1">{{ item.impact_mwh }} MWh</span><span>/</span
          ><span class="ml-1">$</span>{{ item.impact_usd }}
        </div>
      </template>
    </DynamicTable>
  </div>
</template>

<script>
import TableHeaderFilter from "@/components/TableHeaderFilter.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import debounce from "lodash/debounce";

export default {
  name: "AllSiteComponentsTab",
  components: {
    TableHeaderFilter,
    DynamicTable,
  },
  props: {
    issues: {
      type: Array,
      required: false,
      default: () => [],
    },
    siteId: {
      type: Number,
      required: false,
      default: null,
    },
    component: {
      type: String,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showAllIssuesInProgress: false,
      selectedIssue: null,
      selectedColumns: [],
      maxColumns: 7,
    };
  },
  computed: {
    headers() {
      return [
        {
          id: 1,
          text: "TURBINE",
          value: "turbine",
        },
        {
          id: 2,
          text: "COMPONENT NAME",
          value: "component_name",
        },
        {
          id: 3,
          text: "OPEN ISSUES",
          value: "open",
        },
        {
          id: 4,
          text: "TICKETS IN PROGRESS",
          value: "issues_in_progress",
          not_sortable: true,
        },
        {
          id: 5,
          text: "IMPACT (MWh/$)",
          value: "impact_mwh",
        },
        {
          id: 6,
          text: "ISSUES CLOSED",
          value: "closed",
        },
        {
          id: 7,
          text: "LAST UPDATED",
          value: "lastUpdated",
        },
      ];
    },
    selectedHeaders() {
      let result = this.headers;
      if (this.selectedColumns?.length > 0) {
        result = result.filter((item) => {
          const matchedHeader = this.selectedColumns.filter((column) => {
            return column.id === item.id;
          });
          if (matchedHeader?.length > 0) {
            return item;
          }
        });
        return result;
      }
      return [];
    },
  },
  methods: {
    showAllModal(item) {
      this.selectedItem = item;
      this.showAllIssuesInProgress = !this.showAllIssuesInProgress;
    },
    snakeToUpperCamelCase(str) {
      return str
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join("");
    },
    changeSelectedHeaders(header) {
      if (header === "selectAll") {
        if (
          this.selectedColumns.length === this.headers.length ||
          this.selectedColumns.length === this.maxColumns
        ) {
          this.selectedColumns = [];
          return;
        } else {
          this.selectedColumns = this.headers;
        }
      } else if (header === "reset") {
        this.selectedColumns = this.headers;
      } else if (header === "clear") {
        this.selectedColumns = [];
      } else {
        const index = this.selectedColumns.indexOf(header);
        if (index !== -1) {
          this.selectedColumns = this.selectedColumns.filter(
            (item) => item !== header,
          );
        } else {
          if (this.selectedColumns.length >= this.maxColumns) {
            this.selectedColumns.shift();
          }
          this.selectedColumns.push(header);
        }
      }
      this.ensureMaxColumns();
    },
    handleResize() {
      this.ensureMaxColumns();
    },
    ensureMaxColumns() {
      const width = window.innerWidth;
      this.maxColumns = width < 900 ? 4 : this.headers.length;
      if (this.selectedColumns.length > this.maxColumns) {
        this.selectedColumns = this.selectedColumns.slice(0, this.maxColumns);
      }
    },
    indvComponentRoute(item) {
      let name = null;
      if (item.component === "main_bearing") {
        name = "rotor-shaft-assembly";
      } else {
        name = item.component.replace(/[ _]/g, "-");
      }
      const routeObj = {
        name: "SubsystemComponentDetail",
        params: {
          turbineId: item.turbine_id,
          componentName: name,
        },
      };
      return routeObj;
    },
  },
  mounted() {
    this.selectedColumns = this.headers;
    this.handleResize = debounce(this.handleResize, 200);
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.issues-tab-container {
  position: relative;
}

.issue-status {
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  border-radius: 12px;
}

.all-issues {
  position: absolute;
  bottom: 0;
  right: 75px;
  width: 250px;
  border: 1px solid var(--v-mediumGray-base);
  border-radius: 6px;
  box-shadow: 2px 2px 8px 4px rgba(13, 13, 13, 0.3);
  background: var(--v-issueModal-base);
  z-index: 1000;
  padding: 0.5rem;
}

.component-name {
  text-transform: uppercase;
}
</style>
